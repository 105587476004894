.navik-header {
  position: relative;
  z-index: 999;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.428572;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  padding:12px 0;
}

.navik-header *,
.navik-header ::after,
.navik-header ::before {
  box-sizing: border-box
}

.navik-header::after,
.navik-header::before {
  box-sizing: border-box
}

.navik-header ul {
  padding-left: 0;
  margin-bottom: inherit
}

.navik-header img {
  max-width: 100%;
  height: auto;
  vertical-align: top
}

.navik-header a:active,
.navik-header a:focus,
.navik-header a:hover {
  text-decoration: none;
  outline: 0
}

.navik-header .logo {
  padding: 30px 40px;
  text-align: center
}

.navik-header .logo img {
  max-width: 100%;
  height: auto
}

.navik-header-container {
  position: relative
}

.navik-menu>ul {
  display: none;
  padding: 0 0 20px
}

.navik-menu ul li {
  position: relative
}

.navik-menu ul li>a {
  display: block;
  padding: 14px 15px;
  font-size: 18px;
  color: #212121 !important;
  text-transform: uppercase;
  transition: all .2s ease-in-out;
  text-transform: capitalize;
}

.navik-menu ul li.current-menu>a,
.navik-menu ul li:hover>a {
  color: #26c6da;
  background-color: #f6f6f6
}

.navik-menu ul ul li>a {
  padding: 14px 15px 14px 30px;
  font-size: 14px;
  font-style: italic;
  text-transform: inherit;
  background-color: #efefef
}

.navik-menu ul ul li:hover>a {
  background-color: #efefef
}

.navik-menu ul ul ul li>a {
  padding: 14px 15px 14px 45px
}

.navik-menu li>ul {
  display: none
}

.dropdown-plus {
  position: absolute;
  top: 0;
  right: 0;
  width: 49px;
  height: 49px;
  line-height: 49px;
  cursor: pointer
}

.dropdown-plus::after,
.dropdown-plus::before {
  position: absolute;
  top: 24px;
  right: 18px;
  width: 13px;
  height: 1px;
  content: "";
  background-color: #111
}

.dropdown-plus::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.dropdown-plus.dropdown-open::after {
  display: none
}

.header-shadow-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 30px;
  overflow: hidden
}

.header-shadow-wrapper::after {
  position: relative;
  top: -60px;
  display: block;
  width: 100%;
  height: 60px;
  content: "";
  border-radius: 50%;
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, .1)
}

.burger-menu {
  position: absolute;
  top: 35px;
  right: 14px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 20px;
  height: 17px;
  cursor: pointer;
  transition: -webkit-transform 330ms ease-out;
  transition: transform 330ms ease-out;
  transition: transform 330ms ease-out, -webkit-transform 330ms ease-out
}

.burger-menu.menu-open {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.line-menu {
  width: 100%;
  height: 3px;
  background-color: #111;
  border-radius: 2px
}

.line-menu.line-half {
  width: 50%
}

.line-menu.first-line {
  -webkit-transform-origin: right;
  transform-origin: right;
  transition: -webkit-transform 330ms cubic-bezier(.54, -.81, .57, .57);
  transition: transform 330ms cubic-bezier(.54, -.81, .57, .57);
  transition: transform 330ms cubic-bezier(.54, -.81, .57, .57), -webkit-transform 330ms cubic-bezier(.54, -.81, .57, .57)
}

.line-menu.last-line {
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: -webkit-transform 330ms cubic-bezier(.54, -.81, .57, .57);
  transition: transform 330ms cubic-bezier(.54, -.81, .57, .57);
  transition: transform 330ms cubic-bezier(.54, -.81, .57, .57), -webkit-transform 330ms cubic-bezier(.54, -.81, .57, .57)
}

.menu-open .line-menu.first-line {
  -webkit-transform: rotate(-90deg) translateX(3px);
  transform: rotate(-90deg) translateX(3px)
}

.menu-open .line-menu.last-line {
  -webkit-transform: rotate(-90deg) translateX(-3px);
  transform: rotate(-90deg) translateX(-3px)
}

.navik-header.header-dark {
  background-color: #111
}

.navik-header.header-dark .dropdown-plus::after,
.navik-header.header-dark .dropdown-plus::before,
.navik-header.header-dark .line-menu {
  background-color: #fff
}

.header-dark .navik-menu ul li>a {
  color: #fff
}

.header-dark .navik-menu ul li.current-menu>a,
.header-dark .navik-menu ul li:hover>a {
  color: #26c6da;
  background-color: #313131
}

.header-dark .navik-menu ul ul li>a {
  color: #c1c1c1;
  background-color: #212121
}

.navik-menu .mega-menu-container {
  padding: 15px 30px 0
}

.navik-menu .mega-menu-container ul li {
  position: relative
}

.navik-menu .mega-menu-container ul li a {
  padding: inherit;
  font-style: inherit;
  color: inherit;
  background-color: inherit
}

.navik-menu .mega-menu-container ul li:hover a {
  background-color: inherit
}

.navik-menu .mega-menu-container li>ul {
  display: block
}

.navik-menu .mega-menu>ul .dropdown-plus {
  display: none
}

.navik-menu .mega-menu-box {
  margin-bottom: 30px
}

.navik-menu .mega-menu-heading {
  margin-bottom: 12px;
  font-family: "Fira Sans", sans-serif;
  font-size: 15px;
  color: #212121;
  text-transform: uppercase
}

.navik-menu .mega-menu-heading a {
  color: #212121;
  text-decoration: none;
  transition: color .2s ease-in-out
}

.navik-menu .mega-menu-heading a:hover {
  color: #26c6da
}

.navik-menu .mega-menu-desc {
  font-family: "Fira Sans", sans-serif;
  font-size: 14px;
  font-style: italic;
  line-height: 1.428572;
  color: #818181
}

.navik-menu ul.mega-menu-list li a {
  padding: 5px 0;
  font-size: 14px;
  font-style: italic;
  color: #818181
}

.navik-menu ul.mega-menu-list li a:hover {
  color: #26c6da
}

.navik-menu ul.mega-menu-list ul {
  padding-left: 15px !important
}

.navik-menu .mega-menu-thumbnail {
  margin-bottom: 20px
}

.navik-menu .mega-menu-thumbnail img {
  width: 100%;
  height: auto
}

.navik-menu .mega-menu-thumbnail>a {
  position: relative;
  display: block
}

.navik-menu .mega-menu-thumbnail>a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .2s ease-in-out
}

.navik-menu .mega-menu-thumbnail:hover>a::before {
  background-color: rgba(0, 0, 0, .2)
}

.header-dark .navik-menu .mega-menu-container ul li a {
  background-color: inherit
}

.header-dark .navik-menu .mega-menu-heading a {
  color: #fff
}

.header-dark .navik-menu .mega-menu-heading a:hover {
  color: #26c6da
}

.navik-menu .navik-menu-icon {
  margin-right: 5px;
  color: #a1a1a1
}

.navik-menu .mega-menu-heading a .navik-menu-icon,
.navik-menu ul li>a .navik-menu-icon {
  transition: color .2s ease-in-out
}

.navik-menu .mega-menu-heading a:hover .navik-menu-icon,
.navik-menu ul li.current-menu>a .navik-menu-icon,
.navik-menu ul li:hover>a .navik-menu-icon {
  color: #26c6da !important
}

.header-dark .navik-menu .navik-menu-icon {
  color: #717171
}

.navik-menu-overlay .navik-menu-icon {
  position: relative;
  margin-right: 10px
}

.navik-menu-overlay>ul ul li a .navik-menu-icon {
  top: -1px;
  margin-right: 7px
}

.menu-slide .navik-menu-overlay>ul ul li a .navik-menu-icon {
  margin-right: 5px
}

.navik-menu-fixed .navik-menu-icon {
  margin-right: 5px
}

@media (max-width:767.98px) {

  .body-fixed-sidebar,
  .navik-header,
  .navik-header-overlay {
      width: 100%
  }
}

@media (max-width:1199.98px) {

  .body-fixed-sidebar,
  .navik-header,
  .navik-header-overlay {
      width: 100%
  }
}

@media (min-width:768px) {
  .navik-header .logo {
      float: left;
      padding: 30px 40px 30px 0
  }

  .navik-menu {
      clear: both
  }

  .header-shadow-wrapper::after {
      box-shadow: 0 10px 40px -5px rgba(0, 0, 0, .1)
  }

  .navik-menu .mega-menu-media {
      display: table;
      width: 100%
  }

  .navik-menu .mega-menu-media-img {
      display: table-cell;
      width: 120px;
      vertical-align: top
  }

  .navik-menu .mega-menu-media-img .mega-menu-thumbnail {
      margin-bottom: 0
  }

  .navik-menu .mega-menu-media-img img {
      width: 100%;
      height: auto
  }

  .navik-menu .mega-menu-media-info {
      display: table-cell;
      padding-left: 20px;
      vertical-align: top
  }

  .navik-menu-overlay .navik-menu-icon {
      margin-right: 12px
  }

  .navik-menu-overlay>ul ul li a .navik-menu-icon {
      margin-right: 8px
  }

  .menu-slide .navik-menu-overlay .navik-menu-icon {
      margin-right: 10px
  }
}

@media (min-width:1200px) {
  .navik-header-container {
      height: 90px
  }

  .navik-header .logo {
      position: relative;
      top: 50%;
      padding: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%)
  }

  .navik-header .logo img {
      width: auto;
      max-width: inherit;
      max-height: 90px
  }

  .burger-menu {
      display: none
  }

  .navik-menu {
      float: right;
      clear: inherit
  }

  .navik-menu>ul {
      display: block !important;
      padding: 0;
      font-size: 0
  }

  .navik-menu>ul>li {
      display: inline-block
  }

  .navik-menu>ul>li>a {
      position: relative;
      box-sizing: border-box;
      height: 90px;
      padding: 0 15px;
      line-height: 90px;
      white-space: nowrap
  }

  .navik-menu>ul>li:last-child>a {
      padding: 0 0 0 20px
  }

  .navik-menu ul li {
      position: relative
  }

  .navik-menu ul li.dropdown_menu>a>span {
      display: none
  }

  .navik-menu ul li.dropdown_menu>a>span::before {
      position: relative;
      top: -3px;
      box-sizing: content-box;
      display: inline-block;
      width: 4px;
      height: 4px;
      margin-left: 8px;
      content: "";
      border-color: #000000;
      border-style: solid;
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg)
  }

  .navik-menu ul ul li.dropdown_menu>a>span::before {
      top: 7px;
      right: -10px;
      float: right;
      margin: 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg)
  }

  .navik-menu ul ul li>a {
      position: relative;
      padding: 9px 35px 7px !important;
      color: #818181;
      background-color: transparent
  }

  .navik-menu ul ul li>a::before {
      position: absolute;
      top: 17px;
      left: 35px;
      width: 0;
      height: 1px;
      content: "";
      background: #a1a1a1;
      transition: all .2s ease-in-out
  }

  .navik-menu ul ul li:hover>a {
      padding-left: 55px !important;
      color: #818181;
      background-color: transparent
  }

  .navik-menu ul ul li:hover>a::before {
      width: 10px
  }

  .navik-menu li>ul {
      position: absolute;
      top: 100%;
      left: 0;
      display: block !important;
      width: 250px;
      padding: 27px 0;
      visibility: hidden;
      background-color: #fff;
      box-shadow: 0 12px 30px -2px rgba(0, 0, 0, .1);
      opacity: 0;
      transition: all .2s ease-in-out
  }

  .navik-menu li>ul ul {
      top: -27px;
      left: 100%
  }

  .navik-menu li:hover>ul {
      visibility: visible;
      opacity: 1
  }

  .navik-menu li.submenu-right>ul {
      right: 0;
      left: inherit
  }

  .navik-menu li.submenu-right>ul ul {
      left: -100% !important
  }

  .navik-menu.menu-caret ul li.dropdown_menu>a>span {
      display: inline-block
  }

  .navik-menu.menu-caret ul ul li.dropdown_menu>a>span {
      display: inline
  }

  .navik-menu.separate-line>ul>li>a {
      position: relative;
      padding: 0 28px
  }

  .navik-menu.separate-line>ul>li>a::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 20px;
      content: "";
      background-color: #eee;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%)
  }

  .navik-menu.separate-line>ul>li:last-child>a {
      padding: 0 0 0 28px
  }

  .navik-menu.separate-line>ul>li:last-child>a::before {
      display: none
  }

  .header-dark .navik-menu ul li.dropdown_menu>a>span::before {
      border-color: #a1a1a1
  }

  .header-dark .navik-menu ul ul li>a {
      color: #a1a1a1;
      background-color: transparent
  }

  .header-dark .navik-menu ul ul li:hover>a {
      color: #fff;
      background-color: transparent
  }

  .header-dark .navik-menu ul ul li:hover>a .navik-menu-icon {
      color: #fff !important
  }

  .header-dark .navik-menu li>ul {
      background-color: #111
  }

  .header-dark .navik-menu.separate-line>ul>li>a::before {
      background-color: #333
  }

  .center-menu-1 .navik-menu>ul>li:last-child>a,
  .center-menu-2 .navik-menu>ul>li:last-child>a,
  .navik-menu.menu-hover-2>ul>li:last-child>a,
  .navik-menu.menu-hover-3>ul>li:last-child>a,
  .navik-menu.menu-hover-4>ul>li:last-child>a {
      padding: 0 20px
  }

  .center-menu-1 .navik-menu.separate-line>ul>li:last-child>a,
  .center-menu-2 .navik-menu.separate-line>ul>li:last-child>a,
  .navik-menu.menu-hover-2.separate-line>ul>li:last-child>a,
  .navik-menu.menu-hover-3.separate-line>ul>li:last-child>a,
  .navik-menu.menu-hover-4.separate-line>ul>li:last-child>a {
      padding: 0 28px
  }

  .header-dark .navik-menu ul li.current-menu>a,
  .header-dark .navik-menu ul li:hover>a,
  .navik-menu ul li.current-menu>a,
  .navik-menu ul li:hover>a {
      background-color: inherit
  }

  .dropdown-plus {
      display: none
  }

  .navik-header.sticky {
      position: fixed;
      width: 100%;
      -webkit-animation-name: stickyTransition;
      animation-name: stickyTransition;
      -webkit-animation-duration: .5s;
      animation-duration: .5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      top: 0;
  }

  .navik-header.sticky .navik-header-container {
      height: 70px
  }

  .navik-header.sticky .navik-menu>ul>li>a {
      height: 70px;
      line-height: 70px
  }

  .navik-header.sticky .logo {
      padding: 0 40px 0 0
  }

  .navik-header.sticky .logo img {
    max-height: inherit;
    width: 425px;
}

  @-webkit-keyframes stickyTransition {
      from {
          opacity: 0;
          -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0)
      }

      to {
          opacity: 1;
          -webkit-transform: none;
          transform: none
      }
  }

  @keyframes stickyTransition {
      from {
          opacity: 0;
          -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0)
      }

      to {
          opacity: 1;
          -webkit-transform: none;
          transform: none
      }
  }

  .navik-header.header-opacity-on,
  .navik-header.header-transparent-on {
      /* position: absolute; */
      width: 100%;
      background-color: transparent
  }

  .navik-header.header-opacity-on {
      background-color: rgba(0, 0, 0, .3)
  }

  .header-opacity-on .header-shadow-wrapper,
  .header-transparent-on .header-shadow-wrapper {
      display: none
  }

  .header-opacity-on .navik-menu>ul>li>a,
  .header-transparent-on .navik-menu>ul>li>a {
      color: #f7f7f7
  }

  .header-opacity-on .navik-menu>ul>li>a .navik-menu-icon,
  .header-transparent-on .navik-menu>ul>li>a .navik-menu-icon {
      color: rgba(255, 255, 255, .85)
  }

  .header-opacity-on .navik-menu>ul>li.current-menu>a,
  .header-opacity-on .navik-menu>ul>li:hover>a,
  .header-transparent-on .navik-menu>ul>li.current-menu>a,
  .header-transparent-on .navik-menu>ul>li:hover>a {
      color: #26c6da
  }

  .header-opacity-on .navik-menu>ul>li.dropdown_menu>a>span::before,
  .header-transparent-on .navik-menu>ul>li.dropdown_menu>a>span::before {
      border-color: #000000
  }

  .header-transparent-on.center-menu-2 .navik-menu {
      border-color: rgba(255, 255, 255, .25)
  }

  .header-transparent-on .navik-menu.separate-line>ul>li>a::before {
      background-color: rgba(255, 255, 255, .25)
  }

  .header-opacity-on.center-menu-2 .navik-menu {
      border-color: rgba(255, 255, 255, .2)
  }

  .header-opacity-on .navik-menu.separate-line>ul>li>a::before {
      background-color: rgba(255, 255, 255, .2)
  }

  .navik-menu.menu-hover-2>ul>li.current-menu>a,
  .navik-menu.menu-hover-2>ul>li:hover>a {
      color: #212121;
      background-color: #f8f8f8
  }

  .navik-menu.menu-hover-2>ul>li.current-menu>a::after,
  .navik-menu.menu-hover-2>ul>li:hover>a::after {
      opacity: 1
  }

  .navik-menu.menu-hover-2>ul>li>a::after,
  .navik-menu.menu-hover-3>ul>li>a::after {
      position: absolute;
      bottom: -3px;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 3px;
      content: "";
      background-color: #26c6da;
      opacity: 0;
      transition: all .2s ease-in-out
  }

  .navik-menu.menu-hover-3>ul>li.current-menu>a,
  .navik-menu.menu-hover-3>ul>li:hover>a {
      color: #212121
  }

  .navik-menu.menu-hover-3>ul>li.current-menu>a::after,
  .navik-menu.menu-hover-3>ul>li:hover>a::after {
      width: 100%;
      opacity: 1
  }

  .navik-menu.menu-hover-3>ul>li>a::after {
      left: 50%;
      width: 0;
      transition: all .3s ease-in-out;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%)
  }

  .navik-menu.menu-hover-4>ul>li>a>.hover-transition {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      overflow: hidden
  }

  .navik-menu.menu-hover-4>ul>li>a>.hover-transition::after {
      position: relative;
      top: 50%;
      left: 50%;
      display: block;
      width: calc(100% + 2px);
      height: 0;
      content: "";
      background-color: #26c6da;
      opacity: 0;
      transition: all .2s ease-in-out;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%)
  }

  .navik-menu.menu-hover-4>ul>li.current-menu>a,
  .navik-menu.menu-hover-4>ul>li:hover>a {
      color: #fff
  }

  .navik-menu.menu-hover-4>ul>li.current-menu>a>.hover-transition::after,
  .navik-menu.menu-hover-4>ul>li:hover>a>.hover-transition::after {
      height: 100%;
      opacity: 1;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%)
  }

  .navik-menu.menu-hover-4>ul>li.current-menu>a .navik-menu-icon,
  .navik-menu.menu-hover-4>ul>li:hover>a .navik-menu-icon {
      color: #fff !important
  }

  .navik-menu.menu-hover-4>ul>li>ul {
      transition-delay: 0s
  }

  .navik-menu.menu-hover-4>ul>li:hover>ul {
      transition-delay: .13s
  }

  .navik-menu.menu-hover-4>ul>li.dropdown_menu.current-menu>a>span::before,
  .navik-menu.menu-hover-4>ul>li.dropdown_menu:hover>a>span::before {
      border-color: #fff
  }

  .header-dark .navik-menu.menu-hover-2>ul>li.current-menu>a,
  .header-dark .navik-menu.menu-hover-2>ul>li:hover>a {
      color: #fff;
      background-color: #222
  }

  .header-dark .navik-menu.menu-hover-3>ul>li.current-menu>a,
  .header-dark .navik-menu.menu-hover-3>ul>li:hover>a {
      color: #fff
  }

  .header-transparent-on .navik-menu.menu-hover-2>ul>li.current-menu>a,
  .header-transparent-on .navik-menu.menu-hover-2>ul>li:hover>a {
      color: #f7f7f7;
      background-color: rgba(255, 255, 255, .25)
  }

  .header-opacity-on .navik-menu.menu-hover-2>ul>li.current-menu>a,
  .header-opacity-on .navik-menu.menu-hover-2>ul>li:hover>a {
      color: #f7f7f7;
      background-color: rgba(255, 255, 255, .2)
  }

  .header-opacity-on .navik-menu.menu-hover-3>ul>li.current-menu>a,
  .header-opacity-on .navik-menu.menu-hover-3>ul>li:hover>a,
  .header-transparent-on .navik-menu.menu-hover-3>ul>li.current-menu>a,
  .header-transparent-on .navik-menu.menu-hover-3>ul>li:hover>a {
      color: #f7f7f7
  }

  .header-opacity-on .navik-menu.menu-hover-2>ul>li.current-menu>a .navik-menu-icon,
  .header-opacity-on .navik-menu.menu-hover-2>ul>li:hover>a .navik-menu-icon,
  .header-opacity-on .navik-menu.menu-hover-3>ul>li.current-menu>a .navik-menu-icon,
  .header-opacity-on .navik-menu.menu-hover-3>ul>li:hover>a .navik-menu-icon,
  .header-transparent-on .navik-menu.menu-hover-2>ul>li.current-menu>a .navik-menu-icon,
  .header-transparent-on .navik-menu.menu-hover-2>ul>li:hover>a .navik-menu-icon,
  .header-transparent-on .navik-menu.menu-hover-3>ul>li.current-menu>a .navik-menu-icon,
  .header-transparent-on .navik-menu.menu-hover-3>ul>li:hover>a .navik-menu-icon {
      color: rgba(255, 255, 255, .85) !important
  }

  .navik-menu.submenu-top-border li>ul {
      border-top: 3px solid #26c6da
  }

  .navik-menu.submenu-top-border li>ul ul {
      top: -30px
  }

  .navik-menu.submenu-top-border.submenu-list-border li>ul ul {
      top: -23px
  }

  .navik-menu.submenu-list-border li>ul ul {
      top: -20px
  }

  .navik-menu.submenu-list-border ul ul li>a {
      padding: 15px 35px 14px !important
  }

  .navik-menu.submenu-list-border ul ul li:first-child>a {
      padding: 8px 35px 14px 35px !important
  }

  .navik-menu.submenu-list-border ul ul li:last-child>a {
      padding: 15px 35px 8px 35px !important
  }

  .navik-menu.submenu-list-border ul ul li:last-child::after {
      display: none
  }

  .navik-menu.submenu-list-border ul ul li::after {
      position: absolute;
      right: 35px;
      bottom: 0;
      left: 35px;
      height: 1px;
      content: "";
      background-color: #eee
  }

  .navik-menu.submenu-list-border ul ul li:hover>a {
      padding-left: 55px !important
  }

  .navik-menu.submenu-list-border ul ul li:nth-child(n+2)>a::before {
      top: 24px
  }

  .header-dark .navik-menu.submenu-list-border ul ul li::after {
      background-color: #333
  }

  .navik-menu.submenu-flip li>ul {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all .35s ease-in-out;
      -webkit-transform: rotateX(-90deg);
      transform: rotateX(-90deg)
  }

  .navik-menu.submenu-flip li:hover>ul {
      -webkit-transform: rotateX(0);
      transform: rotateX(0)
  }

  .navik-menu.submenu-flip ul {
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-perspective: 200px;
      perspective: 200px
  }

  .navik-menu.submenu-flip ul li {
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-perspective: 200px;
      perspective: 200px
  }

  .navik-menu.submenu-scale li>ul {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      transition: all .3s ease;
      -webkit-transform: scale(0);
      transform: scale(0)
  }

  .navik-menu.submenu-scale li.submenu-right ul {
      -webkit-transform-origin: 100% 0;
      transform-origin: 100% 0
  }

  .navik-menu.submenu-scale li:hover>ul {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  .navik-mega-menu.mega-menu-fullwidth .navik-header-container {
      position: inherit
  }

  .navik-mega-menu .navik-header-container,
  .navik-mega-menu.mega-menu-fullwidth {
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-perspective: 200px;
      perspective: 200px
  }

  .navik-menu .mega-menu-container {
      padding: 15px 15px 0
  }

  .navik-menu .mega-menu-container ul.mega-menu-list li>a::before {
      top: 13px !important;
      left: 0;
      display: block
  }

  .navik-menu.submenu-flip .mega-menu>ul {
      -webkit-perspective: inherit;
      perspective: inherit
  }

  .navik-menu.submenu-flip .mega-menu-container li>ul {
      -webkit-transform: rotateX(0);
      transform: rotateX(0)
  }

  .navik-menu.submenu-scale .mega-menu-container li>ul {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  .navik-menu ul li.mega-menu {
      position: inherit;
      font-size: initial
  }

  .navik-menu li.mega-menu>ul {
      width: 100%;
      padding: 25px 20px 15px
  }

  .navik-menu li.mega-menu:hover .mega-menu-container li>ul {
      visibility: visible;
      opacity: 1
  }

  .navik-menu li.mega-menu.submenu-right>ul ul {
      left: inherit !important
  }

  .navik-mega-menu .navik-menu.submenu-flip ul li.mega-menu,
  .navik-mega-menu .navik-menu.submenu-flip>ul,
  .navik-mega-menu.mega-menu-fullwidth .navik-header-container {
      -webkit-transform-style: unset;
      transform-style: unset;
      -webkit-perspective: none;
      perspective: none
  }

  .navik-menu.submenu-scale li.mega-menu ul,
  .navik-menu.submenu-scale li.mega-menu.submenu-right ul {
      -webkit-transform-origin: 50% 0;
      transform-origin: 50% 0
  }

  .navik-menu .mega-menu-container li ul,
  .navik-menu.submenu-list-border .mega-menu-container li ul,
  .navik-menu.submenu-top-border .mega-menu-container li ul {
      position: relative;
      top: 0 !important;
      left: inherit;
      width: 100%;
      padding: 0;
      border-top: none;
      box-shadow: inherit
  }

  .navik-menu .mega-menu-container ul li>a::before,
  .navik-menu.menu-caret .mega-menu-container li.dropdown_menu>a>span,
  .navik-menu.submenu-list-border .mega-menu-container ul li::after {
      display: none
  }

  .navik-menu .mega-menu-container ul li a,
  .navik-menu .mega-menu-container ul li:hover a,
  .navik-menu.submenu-list-border .mega-menu-container ul li a,
  .navik-menu.submenu-list-border .mega-menu-container ul li:first-child a,
  .navik-menu.submenu-list-border .mega-menu-container ul li:first-child:hover a,
  .navik-menu.submenu-list-border .mega-menu-container ul li:hover a,
  .navik-menu.submenu-list-border .mega-menu-container ul li:last-child a,
  .navik-menu.submenu-list-border .mega-menu-container ul li:last-child:hover a {
      padding: inherit !important
  }

  .navik-menu .mega-menu-container ul.mega-menu-list li a,
  .navik-menu .mega-menu-container ul.mega-menu-list li:hover a,
  .navik-menu.submenu-list-border .mega-menu-container ul.mega-menu-list li a,
  .navik-menu.submenu-list-border .mega-menu-container ul.mega-menu-list li:hover a {
      padding: 5px 0 !important;
      color: #818181
  }

  .navik-menu .mega-menu-container ul.mega-menu-list li a:hover,
  .navik-menu.submenu-list-border .mega-menu-container ul.mega-menu-list li a:hover {
      padding-left: 20px !important
  }

  .header-dark .navik-menu .mega-menu-container ul.mega-menu-list li a {
      color: #a1a1a1
  }

  .header-dark .navik-menu .mega-menu-container ul.mega-menu-list li a:hover {
      color: #fff
  }
}


.navik-header .logo img {
  /* width: 440px; */
  object-fit: cover;
}

/*# sourceMappingURL=navik-horizontal-default-menu.min.css.map */