@media(max-width:1399px) {
    .track_shipment_main {
        width: 75%;
    }

    .offer_item {
        min-height: 440px;
    }
    section.hero_section .carousel-caption {
        /* margin-left: 160px; */
    }
}

@media(max-width:1199px) {
    .main_title h2 {
        font-size: 32px;
    }

    .track_shipment_main {
        padding: 30px 50px;
        width: 80%;
    }

    .navik-header .logo {
        padding: 0;
    }

    .about_inner_main .about_content {
        padding-left: 24px;
    }
}

@media(max-width:991px) {
    .wp-block-columns .wp-block-column {
        display: inline-block;
        margin-right: 40px;
        width: 26%;
    }
    .career_form {
        width: 65%;
    }

    .testimonial_content {
        padding: 35px 40px;
    }

    .testimonial_content:before {
        left: 6px;
        top: 16px;
    }

    .carousel-caption h3 {
        font-size: 32px;
        width: 70%;
        line-height: 39px;
    }

    .track_shipment_main {
        flex-direction: column;
        row-gap: 22px;
        padding: 20px 30px;
    }

    .track_shipment_main h2 {
        font-size: 28px;
    }

    .home_choose_img img {
        height: auto;
    }

    .mission_wrapper {
        text-align: left;
        margin-right: 19px;
    }

    .main_title h2 {
        font-size: 27px;
        line-height: 38px;

    }

    .mission_wrapper .main_title h2 {
        line-height: 40px;
    }

    .industries_main_wrapper p {
        width: 100%;
    }

    .track_shipment_main {
        margin-top: -30px;
    }

    .top_header {
        padding: 10px 60px;
    }

    .top_main .left_wrapper ul {
        flex-direction: column;
        row-gap: 15px;
    }

    .top_header:before {
        width: 60%;
    }

    .top_main .left_wrapper ul li {
        width: max-content;
    }
}

@media(max-width:767px) {
    .career_form {
        width: 100%;
    }

    section.home_choose_section .container>.row {
        flex-direction: column-reverse;
    }

    .track_shipment_main {
        margin-top: 0;
        width: 100% !important;
    }

    .home_track_shipment .container,
    .home_track_shipment .container .row,
    .home_track_shipment .container .row .col {
        width: 100%;
        padding: 0;
        margin: 0;
        max-width: fit-content;
    }

    section.hero_section .carousel-item img {
        height: 400px;
        object-fit: cover;
    }

    section.hero_section .carousel-caption {
        margin: 0px 88px;
        width: 80%;
    }

    .carousel-caption h3 {
        width: 95%;
    }

    .carousel-caption h3 {
        font-size: 26px;
    }

    .navik-header .logo {
        text-align: left;
    }

    .navik-header .logo img {
        width: 312px;
    }

    .burger-menu {
        top: 22px;
    }

    .mission_wrapper {
        margin-bottom: 40px;
    }

    .home_choose_content {
        margin-bottom: 40px;
    }

    .networks_main_Image {
        margin-bottom: 40px;
    }

    .sp {
        padding: 60px 0;
    }

    .about_content {
        display: flex;
        flex-direction: column;
    }

    .about_content .image {
        order: -1;
        display: flex;
        margin-bottom: 40px;
    }
    section.contact_wrapper.sp .page_main_title p {
        width: 100%;
    }
    .contact_main > .row {
        flex-direction: column-reverse;
    }
    .contact_image {
        margin-bottom: 40px;
    }
    .about_inner_main .about_content {
        padding-left: 0px;
    }
    .page_main_title h2 {
        font-size: 24px !important;
        line-height: 36px !important;
    }
    .about_image {
        overflow: hidden;
        height: auto;
        border-radius: 100% 0% 100% 0% / 10% 100% 0% 90%;
        margin-bottom: 60px;
    }
    .vision_main_content h2 {
        font-size: 26px;
        line-height: 36px;
    }
    .management_team_content {
        padding: 0;
    }
    .manangement_item {
        padding: 19px;
        background-color: #eee;
        margin-bottom: 40px;
    }
    section.management_wrapper_main.sp .manangement_item:nth-child(odd) .management_team_content {
        padding: 0;
    }
    ul.social_ico {
        display: none;
    }
    .career_row {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 36px;
    }
}

@media(max-width:575px) {
    .cpoyright {
        flex-direction: column;
    }
    .sp {
        padding: 60px 0;
    }

    .navik-header .logo img {
        width: 195px;
    }

    .burger-menu {
        top: 11px;
    }

    section.hero_section .carousel-caption {
        margin: 0px 40px;
        width: 80%;
    }

    .carousel-caption h3 {
        font-size: 22px;
        text-align: center;
    }

    section.hero_section .carousel-item img {
        height: 325px;
    }

    .hero_section .main_button {
        padding: 10px 13px;
        font-size: 16px;
        margin: 0px auto;
    }

    .track_shipment_main .main_title h2 {
        font-size: 20px;
    }

    .shipment_form {
        padding-left: 0px;
    }

    .form_col {
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
    }

    .form_col .form-label {
        margin-right: 0px;
    }

    .form-check-inline {
        width: max-content;
        margin-right: 0;
    }

    .form_col .mb-3 {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 24px;
    }

    .form_col input.form-control {
        width: 100%;
        margin-right: 0px;
    }

    .choose_usp .row .col-sm-4 {
        width: 50%;
    }

    .home_choose_content {
        padding-left: 0px;
    }

    .networks_main_content {
        padding-left: 0px;
    }

    .testimonial_Image {
        margin-top: 34px;
        margin-left: 0px;
        justify-content: end;
    }

    section.home_mission.sp {
        padding-bottom: 0;
    }

    .track_shipment_main {
        padding: 20px 14px;
    }

    .owl-carousel .owl-item img {
        width: 100%;
    }

    .industries_item .industries_icon {
        width: 80%;
        border-radius: 163px;
    }

    footer.footer_main_wrapper .container .row {
        row-gap: 24px;
        flex-direction: column;
    }

    .top_header {
        display: none;
    }

    .main_title h2 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 16px;
    }

    .mission_wrapper .main_title h2 {
        line-height: 30px;
        margin-bottom: 16px;
    }

    .industries_main_wrapper p {
        font-size: 16px;
        line-height: 28px;
    }

    .main_title h3 {
        font-size: 13px;
    }

    .about_main_tabs {
        overflow: scroll;
    }

    .about_main_tabs ul {
        width: max-content;
        overflow: scroll;
    }

    .about_inner_main .about_content {
        padding-left: 0px;
    }
    section.breadcrumb_main img {
        height: 253px;
        object-fit: cover;
    }
    body h2 {
        font-size: 28px;}
        .tmd{
            text-align: center;
        }
    
}