@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-Thin.woff2') format('woff2'),
        url('GTAmerica-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-ThinItalic.woff2') format('woff2'),
        url('GTAmerica-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-LightItalic.woff2') format('woff2'),
        url('GTAmerica-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-MediumItalic.woff2') format('woff2'),
        url('GTAmerica-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America Ultra';
    src: url('GTAmerica-UltraLight.woff2') format('woff2'),
        url('GTAmerica-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-RegularItalic.woff2') format('woff2'),
        url('GTAmerica-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America Ultra';
    src: url('GTAmerica-UltraLightItalic.woff2') format('woff2'),
        url('GTAmerica-UltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-Regular.woff2') format('woff2'),
        url('GTAmerica-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-Medium.woff2') format('woff2'),
        url('GTAmerica-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-Bold.woff2') format('woff2'),
        url('GTAmerica-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-BoldItalic.woff2') format('woff2'),
        url('GTAmerica-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-Light.woff2') format('woff2'),
        url('GTAmerica-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-Black.woff2') format('woff2'),
        url('GTAmerica-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url('GTAmerica-BlackItalic.woff2') format('woff2'),
        url('GTAmerica-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

