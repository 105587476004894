
body {
    padding: 0;
    margin: 0;
    font-family: 'GT America';
    font-size: 16px;
    line-height: 25px;
    text-align: left;
}
h1,h2,h3,h4,h5,h6{padding: 0;margin: 0;}
ul{list-style: none;padding: 0;margin: 0;}
:root {
    --blue: #2E3192;
    --red:#D71920;
    --grey:#ECECEC;
    --light-blue:#F4F5FF;
    --dark-grey:#4A4A4A;
    --gray:#4D4D4D;
    --black:#000;
    --white:#fff;
    --dark-blue:#0D0F61;
}
p{
font-family:'GT America';
font-size: 19px;
font-weight: 400;
line-height: 30px;
color:var(--gray);
text-align: left;
}
img{width:100%;}
li{
font-family:'GT America';
font-size: 14px;
font-weight: 500;
line-height: 17px;
}
a{text-decoration: none !important;}
.sp{padding: 100px 0;}



.top_header {
    background: #D71920;
    position: relative;
    padding: 10px 100px;
    color: #ffff;
    z-index: 9;
}
.top_main {
    display: flex;
    justify-content: space-between;
}
.left_wrapper ul li a {
    color: var(--white);
    margin-right: 20px;
    font-family: 'GT America';
    font-weight: 500;
}
.left_wrapper ul {
    display: flex;
    margin-bottom: 0;
}
a.navbar-brand {
    width: 330px;
}

.carousel-caption h3 {
    font-size: 36px;
    font-weight: 700;
    width: 60%;
    line-height: 47px;
    color: #fff;
    font-family: 'GT America';
    margin-bottom: 20px;
}
section.hero_section .carousel-caption {
    position: absolute;
    left: 0;
    color: #fff;
    top: 0;
    width: 100%;
    text-align: left;
    display: flex;
    /* transform: translateX(-40%); */
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-left: 0px;
}
section.hero_section .carousel-inner .carousel-item:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: linear-gradient(90deg, #0D0F61 0%, rgba(13, 15, 97, 0.5) 35%, rgba(13, 15, 97, 0.4) 53.5%, rgba(255, 255, 255, 0) 86.51%);
}
.carousel-caption p {
    font-size: 22px;
    line-height: 31px;
    width: 40%;
    margin-bottom: 40px;
    color: var(--white);
}
.main_button {
    display: inline-block;
    background: #D71920;
    padding: 15px 30px;
    color: #fff;
    text-decoration: none;
    font-size: 19px;
    line-height: 15px;
    font-weight: 500;
    font-family: 'GT America';
    transition: all 0.8s ease;
    border: 1px solid transparent;
    border-radius: 5px;
}
.main_button:hover {
    border: 1px solid #D71920;
    color: #D71920;
    transition: all 0.8s ease;
    background-color: #fff;
}
/* .offer_item:hover {
    /* background-image: url('/public/images/packer\ and\ movers.webp');
    width: 100%;
    height: 100%;
    /* background-size: 100%; 
    background-repeat: no-repeat;
    transition: all 0.8s ease;
    /* z-index: -1; 
    cursor: pointer; 
} */
.offer_item {
    background-color: var(--grey);
    padding: 40px;
    transition: all 0.8s ease;
    position: relative;
    min-height: 420px;
}
/* .offer_item:hover .offer_content h3 {
    /* color: #ffff;
    transition: all 0.8s ease; 
}
.offer_item:hover .offer_content p {
    /* color: #fff;
    transition: all 0.8s ease; 
} */
/* .offer_item:hover:before{
    /* z-index: -1;
    transition: all 0.8s ease; 
    opacity: 1;
} */
.offer_content {
    z-index: 9;
    position: relative;
}
/* .offer_item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(13, 15, 97, 0.8);
    /* z-index: -1; 
    transition: all 0.8s ease;
    opacity: 0;
} */
.offer_item .offer_icon {
    width: 100px;
    margin: 0 auto 30px;
    position: relative;
    z-index:9;
}
.main_title h2 {
    font-family: 'GT America';
    font-size: 36px;
    font-weight: 700;
    line-height: 55px;
    color: var(--dark-blue);
    margin-bottom: 30px;
    text-transform: capitalize;
}
.offer_content h3{
    font-family:'GT America';
font-size: 24px;
font-weight: 700;
line-height: 35px;
color: var(--dark-blue);
}
.offer_content p{
font-family: 'GT America';
font-size: 18px;
font-weight: 400;
line-height: 32px;
}
.home_choose_content {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-left: 25px;
}
.home_choose_img{
    height: 100%;
}
.home_choose_img img{
    height: 100%;
}
section.home_choose_section.sp .container .row .col-md-5 {
    height: 100%;
}
.home_choose_content h2{
    margin-bottom: 24px;
}
.home_choose_content p {
    margin-bottom: 40px;
}
.choose_usp_item {
    /* background-color: var(--dark-blue); */
    color: #fff;
    height: 100%;
    /* padding: 10px; */
}
/* .choose_usp_item .choose_img {
    width: 65px;
    margin: 15px auto 20px;
} */
.choose_usp_item h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    color: #fff;
}
.mission_wrapper {
    text-align: left;
    margin-right: 60px;
}
.mission_wrapper ul.mb-3.nav.nav-tabs li {
    margin-right: 34px;
}
.mission_wrapper .main_title h2 {
    line-height: 50px;
}
.mission_wrapper ul.mb-3.nav.nav-tabs li button {
    transition: all 0.7s ease;
    border: 2px solid transparent;
    font-size: 23px;
    line-height: 28px;
    font-weight: 500;
    font-family: 'GT America';
    color: #4A4A4A;
    text-align: left;
    padding-left: 0;
    /* padding-right: 34px; */
    display: inline-block;
    padding: 0;
    padding-bottom: 10px;
}
.mission_wrapper ul.mb-3.nav.nav-tabs li button.nav-link.active {
    color: #ff0000;
    border-bottom: 2px solid #ff0000;
}
.mission_wrapper .nav-tabs .nav-link{
    margin-bottom: 0;
}
ul.mb-3.nav.nav-tabs {
    border-bottom: 1px solid #BFBFBF;
}
.mission_wrapper p {
    margin-bottom: 30px;
}

section.home_industries.sp {
    background-color: var(--dark-blue);
    color: var(--white);
}
.industries_main_wrapper .main_title h2 {
    color: var(--white);
    margin-bottom: 10px;
}
.industries_main_wrapper p {
    color: var(--white);
    width: 60%;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
}
.industries_item .industries_icon img {
    transition: all 0.8s ease;
    transform: scale(1);
}
.industries_item .industries_icon:hover img {
    transition: all 0.8s ease;
    transform: scale(1.1);
}
.industries_item .industries_icon {
    width: 140px;
    margin: 0 auto;
    margin-bottom: 20px;
    border-radius: 120px;
    overflow: hidden;
    transition: all 0.8s ease;
}
.industries_item h3 {
    font-size: 19px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: #fff;
}
.main_title h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    color: var(--dark-blue);
}
.networks_main_content p {
    margin-bottom: 50px;
}
.networks_main_content .main_title h2 {
    margin-bottom: 20px;
}
.networks_main_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding-left: 70px;
}
section.home_testimonial.sp {
    background-color: var(--dark-blue);
    color: var(--white);
    text-align: left;
}
.testimonial_main_wrapper .main_title h2 {
    color: var(--white);
    margin-bottom: 65px;
    text-align: center;
}
.testimonial_content {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    background-color: #ffff;
    padding:35px 60px;
    border-radius: 8px;
    border: 1px solid #dddddd;
    position: relative;
}
.testimonial_Image h3 {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    color: #ffff;
    margin-bottom: 0;
}
.testimonial_Image p {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    margin-bottom: 0;
}
.testimonial_Image {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 50px;
    margin-left: 100px;
}
.user_icon {
    width: 60px;
    overflow: hidden;
    border-radius: 100%;
    margin-right: 20px;
}
.testimonial_content:after {
    content: '';
    position: absolute;
    left: 100px;
    bottom: -30px;
    border: 15px solid;
    border-color: white transparent transparent;
}
.testimonial_content:before {
    content: '';
    position: absolute;
    left: 20px;
    top: 30px;
    background-image: url('/public/images/quote.webp');
    z-index: 99999;
    width: 30px;
    height: 30px;
    background-size: 100%;
    background-repeat: no-repeat;
}
.testimonial_main_wrapper .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 50px;
}
.testimonial_main_wrapper .owl-theme .owl-dots .owl-dot.active span{
    background:#D71920;
}
.owl-theme .owl-dots .owl-dot span{
    background: #fff;
    border: 1px solid #D71920;
}
.clients_main_wrapper .main_title h2 {
    margin-bottom: 50px;
}
.clients_main_wrapper .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 50px;
}
.clients_main_wrapper .owl-theme .owl-dots .owl-dot.active span{
    background:#2E3096;
}
section.home_choose_section.sp {
    padding-bottom: 0;
}
.clients_item {
    padding: 20px;
    height: 170px;
    border: 1px solid #B9B9B9;
}
.clients_main_wrapper .owl-theme .owl-dots .owl-dot span{
    background: #fff;
    border: 1px solid #2E3096;
}
.offer_carousel_main .owl-theme .owl-dots .owl-dot.active span {
    background: #2E3096;
}
.offer_carousel_main .owl-theme .owl-dots .owl-dot span{
    background: #fff;
    border: 1px solid #2E3096;
}
footer.footer_main_wrapper.sp {
    background-color: #f3f3f3;
    color: var(--dark-grey);
    padding-bottom: 20px;
}
.footer_col{
    text-align: left;
}
.footer_col h3{
font-size: 24px;
font-weight: 500;
line-height: 30px;
color: var(--red);
text-transform: capitalize;
}
.footer_logo {
    width: 180px;
    margin: 0 auto;
}
.footer_col span {
    width: 71px;
    height: 2px;
    background: var(--red);
    display: block;
    margin-bottom: 20px;
}
.footer_col ul li a{
font-family: GT America;
font-size: 19px;
font-weight: 400;
line-height: 42px;
color: var(--dark-grey);
}
.footer_col ul {
    padding-left: 0;
}
.footer_col.last_col ul li {
    display: flex;
    align-items: baseline;
}
.footer_col.last_col ul li .icon {
    width: 20px;
    height: 23px;
    margin-right: 10px;
}
.footer_col.last_col ul li .icon img {
    width: 100%;
    height: 100%;
}
.footer_col.last_col ul li p {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    color: var(--dark-grey);
    width: 90%;
}
.social_icons ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.social_icon svg {
    font-size: 30px;
}
.social_icon svg {
    font-size: 16px;
    background-color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    padding: 10px;
}
.social_icon svg path {
    fill: var(--dark-blue);
}
.social_icons ul li {
    margin-right: 10px;
}
.cpoyright p{
    color: var(--dark-grey);
}
.cpoyright {
    margin: 30px 0 0;
    color: var(--white);
    text-align: left;
    border-top: 1px solid var(--dark-blue);
    padding-top: 30px;
}
.offer_item:hover .offer_icon svg path {
    fill: #fff;
}
.top_header:before {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    background: rgb(46,49,146);
    height: 100%;
    left: 0;
    z-index: -11;
    clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
}

.navbar-expand-lg .navbar-nav {
    flex-direction: row;
    margin-right: 60px !important;
    margin-left: auto;
}
section.hero_section .carousel-indicators {
    display: none;
}
.navbar-expand-lg .navbar-nav .nav-link {
    font-family: GT America;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #000000;
}
a.main_button.top_btn:hover {
    background-color: #fff;
    border: 1px solid Var(--dark-blue);
    color: var(--dark-blue);
}

a.main_button.top_btn {
    background-color: var(--dark-blue);
    padding: 17px 30px;
}
.track_shipment_main {
    background-color: #F4F5FF;
    padding: 30px 70px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    margin-top: -50px;
    align-items: center;
    position: relative;
}
section.hero_section {
    position: relative;
}
.shipment_form {
    padding-left: 40px;
}
.form_col {
    display: flex;
}
.form_col .form-label {
    font-family: GT America;
    font-size: 18px;
    font-weight: 700;
    line-height: 17px;
    margin-right: 30px;
}
.form_col .form-check-input:checked {
    background-color: #0D0F61;
    border-color: #0D0F61;
}
.form_col button {
    background-color: #D71920;
    display: inline-block;
    background: #D71920;
    padding: 15px 40px;
    color: #fff;
    text-decoration: none;
    font-size: 19px;
    line-height: 15px;
    font-weight: 500;
    font-family: 'GT America';
    transition: all 0.8s ease;
    border: 1px solid transparent;
}
.form_col button:hover {
    border: 1px solid #D71920;
    color: #D71920;
    transition: all 0.8s ease;
    background-color: #fff;
}
.form_col input.form-control {
    width: 90%;
    margin-right: 30px;
    border-radius: inherit;
}
.form_col input.form-control:focus{
    border-bottom: 1px solid transparent;
    box-shadow: none;
}
.form_col input.form-control:focus {
    border-bottom: 1px solid Var(--dark-blue) !important;
    box-shadow: none !important;
    border: 1px solid Var(--dark-blue);
}
.track_shipment_main .main_title h2 {
    text-transform: uppercase;
    margin-bottom: 0;
    text-align: left;
    line-height: 41px;
}
.cpoyright p {
    font-size: 17px;
    line-height: 24px;
}
.clients_main_wrapper .main_title h2 {
    margin-bottom: 50px;
    text-transform: capitalize;
}


.mission_wrapper ul .counters_main h3 {
    font-size: 19px;
    line-height: 22px;
    color: var(--dark-grey);
    width: 80%;
    height: 60px;
    font-weight: 400;
    text-align: center;
}
.mission_wrapper ul .counters_main h2 {
    font-size: 46px;
    line-height: 54px;
    font-weight: 600;
    color: var(--red);
}

.mission_wrapper ul li {
    margin-right: 20px;
    /* border-right: 1px solid #b7b4b487; */
    width: 33.33%;
}
.mission_wrapper ul {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
}
.counters_main {
    padding: 0;
    position: relative;
}
.mission_wrapper ul li:last-child {
    border-right: none;
}
.counters_main:after {
    content: '';
    position: absolute;
    top: 30px;
    width: 2px;
    height: 30px;
    background: #d3c7c73d;
    right: 0px;
}
.mission_wrapper ul li:last-child .counters_main::after{
    display: none;
}
.breadcrumb_inner h1 {
    color: #fff;
}
section.breadcrumb_main:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    background: linear-gradient( 90deg , rgba(2,0,36,0.773546918767507) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
}
.breadcrumb_main {
    position: relative;
}

/* .breadcrumb_inner {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: left;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: inherit;
    flex-direction: column;
} */
section.breadcrumb_main {
    position: relative;
}
section.breadcrumb_main .container {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.breadcrumb_inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: inherit;
    flex-direction: column;
}

.breadcrumb_inner ul {
    display: flex;
    justify-content: center;
    align-items: center;
}
.breadcrumb_inner ul li {
    font-size: 17px;
    line-height: 27px;
    color: #fff;
    margin-right: 20px;
}
.breadcrumb_inner ul li a{
    font-size: 17px;
    line-height: 27px;
    color: #fff;
}
.breadcrumb_inner ul li a svg {
    margin-left: 10px;
}
.about_main_tabs ul li {
    margin-right: 20px;
}
.about_main_tabs ul li:last-child{
    margin-right: 0;
}
.about_main_tabs ul li a {
    /* display: inline-block; */
    /* padding: 15px 20px; */
    /* border: 2px solid Var(--dark-grey); */
    /* font-size: 17px; */
    /* line-height: 24px; */
    color: #000;
    /* font-weight: 500; */
    /* transition: all 0.8s ease; */
    position: relative;
}
.about_main_tabs ul li a:hover {
    /* background-color: var(--dark-grey); */
    color: var(--red);
    transition: all 0.8s ease;
}
.about_main_tabs ul li a svg {
    padding-left: 10px;
    display: none;
}
.about_main_tabs ul {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 34px;
}
.about_main_tabs {
    margin-bottom: 80px;
}
.about_image {
    overflow: hidden;
    height: 650px;
    border-radius: 100% 0% 100% 0% / 10% 100% 0% 90%;
    margin-bottom: 60px;
}
.image {
    margin-top: 80px;
}
.about_inner_main .about_content {
    text-align: left;
    padding-left: 70px;
}
.about_image img {
    width: 100%;
    height: 100%;
}
.page_main_title h2{
    font-family: 'GT America';
    font-size: 36px;
    font-weight: 700;
    line-height: 55px;
    color: var(--dark-blue);
    text-transform: uppercase;
    margin-bottom: 10px;
}
/* .page_main_title:before {
    top: 0;
    width: 50px;
    height: 3px;
    background: var(--red);
    position: absolute;
    content: '';
} */
.page_main_title {
    padding: 0 0 30px 0;
    position: relative;
    text-align: left;
}
.page_main_title h4 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;

}
.about_content p {
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 30px;
}
.about_inner_main .about_content p:nth-child(3) {
    color: var(--dark-blue);
    font-weight: 600;
    /* font-size: 16px; */
}

.clients_item_wrapper .clients_items img {
    width: 100%;
    height: 100%;
}

.clients_item_wrapper .clients_items {
    border: 1px solid var(--dark-grey);
    margin-bottom: 40px;
    height: 180px;
}
.manangement_item {
    padding: 40px;
    background-color: #eee;
    margin-bottom: 40px;
}
.management_team_content {
    text-align: left;
    padding-left: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.management_team_content h3 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 20px;
}

.management_team_content h2 {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 10px;
    color: var(--dark-blue);
    font-weight: 600;
}
section.management_wrapper_main.sp .manangement_item:nth-child(odd) .row {
    flex-direction: row-reverse;
}
section.management_wrapper_main.sp .manangement_item:nth-child(odd) .management_team_content {
    padding-left: 0;
    padding-right: 60px;
}
.news_image {
    margin-bottom: 30px;
}
.new_item:hover h3 {
    color: #fff;
    transition: all 0.9s ease-in-out;
}

.new_item h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
    font-weight: 500;
    transition: all 0.9s ease;
}
.new_item:hover {
    background: var(--dark-blue);
    transition: all 0.9s ease;
}
.new_item {
    padding: 30px;
    height: 100%;
    background: #f7f7f7;
    transition: all 0.9s ease;
    text-align: left;
    clip-path: polygon(100% 85%, 83% 100%, 1% 100%, 1% 0, 100% 0);
    cursor: pointer;
}
.new_item .date h5 {
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
    color: Var(--dark-grey);
}

.new_item .date {
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    align-items: center;
}
.new_item .date{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
}
.date h5{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: var(--dark-grey);
}
.new_item:hover .date h5 {
    color: #fff;
  
}
.new_item:hover .date svg path{
    fill: #fff;
}

section.single-article-main.sp {
    text-align: left;
}

.single_article_content img {
    margin-bottom: 30px;
    border-radius: 20px;
}
.single_article_content .page_main_title h2 {
    font-size: 30px;
    text-transform: capitalize;
    margin-bottom: 0;
    line-height: 37px;
}
.related-article h3 {
    font-size: 23px;
    line-height: 30px;
    margin-bottom: 25px;
}
.related-article ul li {
    margin-bottom: 15px;
}
.realated-main {
    display: flex;
    justify-content: center;
}
.related-img {
    width: 50%;
}
.related-title {
    padding-left: 20px;
}
.related-title p {
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #464646;
}
.related-title h4 {
    font-size: 15px;
}
.mision_main_content h3 {
    font-size: 29px;
    line-height: 37px;
    text-align: left;
    margin-bottom: 30px;
    color: var(--dark-blue);
}
.about_inner_main .mission_content:nth-child(odd) {
    background-color: #f7f7f7;
}
/* .about_inner_main .mission_content:nth-child(odd) .row {
    flex-direction: row-reverse;
} */

section.about_main_wrapper.vision_wrapper.sp {
    padding-bottom: 0;
}
section.about_main_wrapper.vision_wrapper.sp .about_main_tabs {
    margin-bottom: 0;
}
section.vision_wrapper.sp {
    padding: 60px 0;
    /* padding-bottom: 0; */
}
section.about_main_wrapper.vision_wrapper.sp{
    background-color: #fff !important;
}
/* section.vision_wrapper.sp:nth-child(even){
    background-color: #f7f7f7;
    padding-bottom: 60px;
} */
.core_icon {
    width: 110px;
    height: 110px;
    background: #fff;
    padding: 25px;
    border-radius: 100px;
    margin: 0 auto 20px;
    border: 1px solid var(--dark-blue);
}
.core_item h3 {
    font-size: 20px;
    line-height: 29px;
    font-weight: 500;
    color: #000;
    text-align: center;
}
section.core_values.sp {
    text-align: left;
}

section.contact_wrapper.sp .page_main_title p {
    width: 50%;
}
.contact_form input {
    background: #ffffff;
    border: 1px solid #d3ddde;
    padding: 15px 25px;
    margin-bottom: 25px;
    font-size: 15px;
    border-radius: 5px;
}
.contact_form textarea.form-control:focus {
    border-color: var(--dark-blue);
    box-shadow: none;
}
.contact_form textarea.form-control{
height: 160px !important;
background: #ffffff;
border: 1px solid #d3ddde;
padding: 15px 25px;
margin-bottom: 25px;
font-size: 15px;
border-radius: 5px;
resize: none;
}

.contact_form input:focus {
    border-color: var(--dark-blue);
    box-shadow: none;
}
.contact_form {
    text-align: left;
}
section.map_image iframe {
    padding: 0;
    margin: -10px;
}
.contact_image img {
    height: 100%;
}
.contact_image {
    height: 100%;
}
.services_item {
    padding: 30px;
    position: relative;
    box-shadow: 0 1rem 40px 0px rgba(45,55,75,0.1);
    height: 100%;
    border: 1px solid #ffffffe8;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
    text-align: left;
}

.services_icon {
    width: 50px;
    margin: 0 0 16px;
}
.services_item h3 {
    font-size: 23px;
    line-height: 34px;
    margin-bottom: 20px;
    color: var(--dark-blue);
}
.news_main .col-md-4 {
    margin-bottom: 60px;
}
.single_article_content h3 {
    text-align: left;
    margin-bottom: 20px;
}
.single_article_content ul li {
    font-family: 'GT America';
    font-size: 19px;
    font-weight: 400;
    line-height: 30px;
    color: var(--gray);
    text-align: left;
    margin-bottom: 20px;
}
.single_article_content ul {
    text-align: left;
    list-style: disc;
    padding-left: 30px;
    margin-bottom: 30px;
}
.service_item img {
    height: 100%;
}
.service_item {
    height: 300px;
    margin-bottom: 30px;
}
.faculty_item {
    margin-bottom: 70px;
    position: relative;
    transition: all 0.8s ease;
}
.faculty_image {
    transition: all 0.8s ease;
    overflow: hidden;
    height: 320px;
}
.faculty_item:hover .faculty_image img {
    transform: scale(1.1);
    transition: all 0.8s ease;
}


.faculty_image img {
    height: 100%;
}
.faculty_content {
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 280px;
    background: #ffffff;
    padding: 25px;
    height: 120px;
    box-shadow: 2px 2px 5px #eee;
}
.faculty_content h3 {
    font-size: 20px;
    line-height: 24px;
    /* height: 40px; */
    color: Var(--dark-blue);
    font-weight: 700;
    margin-bottom: 10px;
}
.faculty_content h4 {
    /* opacity: 0; */
    font-size: 15px;
    line-height: 20px;
    text-transform: capitalize;
    /* display: none; */
    font-weight: 400;
}
section.vision_pointers.sp {
    background-color: var(--grey);
}
section.vision_wrapper.sp{
    padding-bottom: 0;
}


section.vision_pointers.sp .page_main_title h2 {
    text-transform: capitalize;
    width: 80%;
    font-size: 43px;
}

.vision_main_content h2 {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 30px;
    font-weight: 700;
    text-align: left;
}
section.vision_wrapper.sp .row .col-md-9{
    margin: 0 auto;
}
section.vision_content.sp .row .col-md-9{
    margin: 0 auto;
}
.about_main_tabs {
    margin-bottom: 0;
    padding: 25px 0;
    background: #eeee;
}
.footer_col.last_col ul li .icon svg path {
    fill: var(--blue);
}
.left_wrapper ul li a {
    padding-left: 10px;
}
.vision_image {
    margin-bottom: 30px;
}
.management_team_image {
    border-radius: 50%;
    overflow: hidden;
}

.single_article_content  h2 {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 0;
    text-transform: capitalize;
    color: #0d0f61;
    color: var(--dark-blue);
    font-family: 'GT America';
    margin-bottom: 20px;
}
.single_article_content img{
    width: auto;
}
.single_article_content {
    text-align: left;
}
.wp-block-columns .wp-block-column {
    display: inline-block;
    margin-right: 40px;
}
section.single-article-main.single-news.sp .single_article_content img {
    width: 100%;
}
section.vision_content ul li {
    font-weight: 400;
    font-size: 17px;
}

section.vision_content ul {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 32px;
    list-style: disc;
    justify-content: right;
    align-items: start;
    padding-left: 24px;
}
.navik-menu>ul>li>a.active {
    color: #d71920 !important;
}
.about_main_tabs ul li a {
    text-transform: uppercase;
}
.about_main_tabs ul li {
    position: relative;
}

.about_main_tabs ul li:before {
    content: '';
    height: 30px;
    width: 2px;
    background: var(--dark-blue);;
    display: block;
    position: absolute;
    top: -6px;
    left: -33px;
}
.about_main_tabs ul li:first-child:before{
    display: none;
}
.offer_item {
    display: grid;
}
.offer_content {
    align-self: end;
}
/* .offer_item:before {
    display: none;
} */
.pointers_content ul li {
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    text-align: initial;
}
.pointers_content ul {
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: right;
    list-style: disc;
    margin-bottom: 32px;
    padding-left: 24px;
    row-gap: 20px;
}
.offer_item {
    min-height: 500px;
    background-size: cover;
    position: relative;
}
 .offer_item:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to top, rgb(0 0 0 / 26%), rgb(0 0 0 / 0%));
    background-size: cover;
    width: 100%;
    height: 100%;
} 
.offer_item h3 {
    color: #fff;
}
.state_outer {
    border: 1px solid #5E5E5E;
    padding: 19px;
    margin: 0px 31px;
    text-align: left;
}
.state_outer h3 {
    font-size: 48px;
}
.City ul {
    padding: 14px 0px 0px 19px;
}
.City ul li {
    font-size: 18px;
    list-style: disc;
}
.about_main_tabs ul li a.active {
    color: red;
}
section.home_mission.sp {
    padding-bottom: 0;
}
.state_name h2 ul li {
    font-size: 19px;
    margin-bottom: 10px;
}

.state_name h2 ul {
    list-style: disc;
    text-align: left;
    padding-left: 18px;
    margin-top: 18px;
}
.vision_main_content div {
    font-size: 19px;
    font-weight: 400;
    line-height: 30px;
    color: var(--gray);
    text-align: left;
}
.career_form .form_field {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 8px;
    margin-bottom: 20px;
}
.career_form .form_field input {
    background: #ffffff;
    border: 1px solid #d3ddde;
    padding: 15px 25px;
    margin-bottom: 25px;
    font-size: 15px;
    border-radius: 5px;
    width: 100%;
}
/* .career_form {
    width: 50%;
} */
.state_name {
    border: 2px solid #000;
    padding: 16px 32px;
}
section.sp.ddy .row .col-md-5 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.wp-block-columns .wp-block-column img {
    width: 100%;
    height: auto;
}
.wp-block-columns .wp-block-column {
    display: inline-block;
    margin-right: 23px;
    width: 27%;
}
.services_item p h2 {
    font-size: 20px;
}
.services_item p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

section.hero_section .carousel-control-prev {
    width: 40px;
}
section.hero_section .carousel-control-next {
    width: 40px;
}
/* api */
.responseApi ul {
    text-align: left;
}
.acareModal {
    z-index: 999999;
}
.acareModal ul li {
    display: flex;
    margin-bottom: 7px;
    border-bottom: 1px solid #ede9e9;
    padding-bottom: 7px;
    flex-wrap: wrap;
}
.acareModal ul li h5 {
    width: 50%;
    font-size: 17px;
}

.acareModal ul li .apiRightData {
    width: 50%;
    font-size: 17px;
}

.acareModal ul li h5.podHeading {
    width: 100%;
    margin-bottom: 10px;
}

.acareModal ul li .apiRightData.podImg{
    width: 100%;
}
/* api  tracking page*/

.trackingOuter {
    text-align: left;
}
.shipmentSummary {
    background: #3e4096;
    color: #fff;
    padding: 6px 10px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}

.shipmentSummary p {
    color: #fff;
    margin-bottom: 0;
}

p.printPdf {
    cursor: pointer;
}

.apiData .api1 p {
    color: #222;
    font-size: 16px;
    line-height: 26px;
    width: 35%;
    margin-bottom: 8px;
}
.api1 {
    display: flex;
}
.apiData {
    display: flex;
}
.api1.apiPod {
    display: block;
}
.apiDataLeft {
    width: 50%;
    border: 1px solid #f7f7f7;
}
.apiDataRight {
    width: 50%;
}

.apiDataLeft .api1 {
    background-color: #f7f7f7;
    padding: 5px;
}

.apiDataLeft .api1:nth-child(even) {
    background-color: #fff;
}

.api1.apiPod p {
    width: 100%;
    margin-left: 20px;
}
.api1.apiPod .api1DataRt {
    width: 100%;
}

.api1data {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.tracking-detail {
    padding: 100px 0;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.api1DataRt {
    color: #222;
    font-size: 16px;
    line-height: 26px;
    width: 60%;
}
.navik-header {
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
}

.noDataFound {
    text-align: center;
    margin-top: 50px;
    font-size: 28px;
}

.track-hist {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.track-hist-box {
    background: #3e4096;
    margin-right: 20px;
    padding: 5px 30px;
    position: relative;
    height: 43px;
    margin-right: 5px;
    margin-bottom: 20px;
}

.track-hist-box:before {
    border-bottom: 21px solid #3e4096;
    border-left: 21px solid transparent;
    border-right: 21px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    right: -31px;
    top: 11px;
    width: 0;
    transform: rotate(90deg);
    z-index: 9;
}

.track-hist-box p {
    font-size: 14px;
    color: #fff;
}

.track-hist-box p img {
    width: 30px;
    margin-right: 10px;
}

.track-hist-box:after {
    border-bottom: 21px solid #fff;
    border-left: 21px solid transparent;
    border-right: 21px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    left: -11px;
    top: 11px;
    width: 0;
    transform: rotate(90deg);
    z-index: 1;
}

table#table-data-all-api {
    width: 100%;
}

table#table-data-all-api thead tr {
    background: #3e4096;
}

table#table-data-all-api thead tr th {
    color: #fff;
    padding: 5px 15px;
    font-weight: 400;
}

table#table-data-all-api tr td {
    width: 25%;
    padding: 5px 15px;
    border: 1px solid #084298;
    font-size: 16px;
}

.testimonial_content {
    color: var(--gray);
}

.trackShi input{
    border: 1px solid #000;
    border-radius: 4px;
    padding: 5px 10px;
    margin-right: 10px;
}

.error-message {
    text-align: left;
    color: #d71920;
}

@media(max-width:991px){
    .apiData {
        flex-wrap: wrap;
    }
    .apiDataLeft {
        width: 100%;
        margin-bottom: 20px;
    }
    .apiDataRight {
        width: 100%;
    }
}

@media(max-width:767px){
    .tracking-detail {
        padding: 50px 0;
    }
    .api1 {
        width: 100%;
        margin-bottom: 5px;
    }
    
}

@media(max-width:575px){
    .apiData .api1 p {
        width: 50%;
    }
    .api1DataRt {
        width: 50%;
    }
    .table-data-all {
        overflow: auto;
    }
    
}

section.Coming_soon.sp h1 {
    font-size: 73px;
    font-weight: 700;
}
.left_wrapper {
    display: flex;
}
ul.social_ico li a svg {
    height: 21px;
}

ul.social_ico li a {
    margin-right: 8px;
}
ul.social_ico {
    display: flex;
    margin-right: 20px;
}
.footer_col.last_col ul li a {
    display: contents;
}
/* thank you */

.thank_head p {
    text-align: center;
    padding-bottom: 10px;
    font-size: 21px;
    font-weight: 500;
    line-height: 30px;
    }
    .thank_head p:first-child {
    color: #00247d;
    }
    
    .thank_head_two {
    text-align: center;
    margin-top: 25px;
    }
    
    .thank_head_two p {
    font-size: 19px;
    font-weight: 500;
    line-height: 30px;
    padding-bottom: 10px;
    }
    
    .thank_head_two p:first-child {
    color: red;
    }
    .thank_head_two p a {
        color: #000;
    }
    .thank_you * {
        text-align: center;
    }

    /* ERRROR */

    section.error p {
        font-size: 19px;
        margin: 18px 0px;
        text-align: center;

    }
    
    section.error h2 {
        font-size: 100px;
        margin-bottom: 0px;
    }
    section.error {
        text-align: center;
    }
    .api1DataRt {
        color: #222;
        font-size: 16px;
        line-height: 26px;
        width: 60%;
        word-wrap: break-word;
    }
    .cpoyright {
        display: flex;
        justify-content: space-between;
    }
    ul.social_ico {
        flex-direction: row !important;
        align-items: center;
    }    
    .service_sidebar ul li a h5 {
        color: #000;
        font-size: 16px;
        margin-bottom: 18px;
    }
    .service_sidebar {
        background: #dc354521;
        padding: 44px 30px 64px 30px;
        text-align: left;
        position: sticky;
        top: 17px;
        border-radius: 12px;
    }    
    .service_sidebar h4 {
        margin-bottom: 24px;
    }
    .service_sidebar ul li a h5:before {
        border-style: solid;
        border-width: 0.15em 0.15em 0 0;
        content: '';
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        top: 0.15em;
        transform: rotate(45deg);
        vertical-align: top;
        width: 0.45em;
        margin-right: 10px;
        margin-top: 4px;
    }
    .related-main {
        display: flex;
    }
